<template>
    <div>
        <div class="card text-dark bg-red XXXw-75 XXXmx-auto">
            <div class="card-body">
                <h1 class="font-weight-bold text-white">
                    OBJEDNÁVKA
                </h1>

                <form>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h4 class="text-center text-uppercase text-white">
                                počet ks
                            </h4>
                        </div>
                        <div class="col-md-5 text-center">
                            <b-form-spinbutton
                                id="produkt_pocet_ks"
                                :min="objednavka_data.produkty[0].pocet_min"
                                :max="objednavka_data.produkty[0].pocet_max"
                                v-model="objednavka_data.produkty[0].pocet_ks"
                                @change="recalculatePrice"
                                inline
                            >
                            </b-form-spinbutton>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h4 class="text-center text-uppercase text-white">
                                způsob dopravy
                            </h4>
                        </div>
                        <div class="col-md-5 text-center">
                            <b-form-select
                                :options="objednavka_data.druhy_dopravy"
                                v-model="objednavka_data.druh_dopravy_id"
                                @change="recalculatePrice"
                            >
                            </b-form-select>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h4 class="text-center text-uppercase text-white">
                                způsob platby
                            </h4>
                        </div>
                        <div class="col-md-5 text-center">
                            <b-form-select
                                :options="objednavka_data.druhy_platby"
                                v-model="objednavka_data.druh_platby_id"
                                @change="recalculatePrice"
                            >
                            </b-form-select>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h4 class="text-center text-uppercase text-white">
                                cena zboží
                            </h4>
                        </div>
                        <div class="col-md-5 text-center">
                            <h4 class="font-weight-bold text-center text-white">
                                {{ objednavka_data.cena_zbozi }} Kč
                            </h4>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h4 class="text-center text-uppercase text-white">
                                cena dopravy
                            </h4>
                        </div>
                        <div class="col-md-5 text-center">
                            <h4 class="font-weight-bold text-center text-white">
                                {{ objednavka_data.cena_dopravy }} Kč
                            </h4>
                        </div>
                    </div>
                    
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h3 class="text-center text-uppercase text-white">
                                celkem k úhradě
                            </h3>
                        </div>
                        <div class="col-md-5 text-center">
                            <h4 class="text-center">
                                <span class="badge badge-pill badge-white fs-4 pl-5 pr-5">
                                    {{ objednavka_data.cena_celkem }}
                                </span>
                                <span class="font-weight-bold text-white">
                                    Kč
                                </span>
                            </h4>
                        </div>
                    </div>
                </form>

                <!--<form class="row g-3">
                    <div class="col-md-12">
                        <b-form-group
                            label="počet ks"
                            :label-class="['font-weight-bold','text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="pocet_ks"
                            label-align="left"
                            >
                            
                            <b-form-spinbutton
                                id="produkt_pocet_ks"
                                :min="objednavka_data.produkty[0].pocet_min"
                                :max="objednavka_data.produkty[0].pocet_max"
                                v-model="objednavka_data.produkty[0].pocet_ks"
                                @change="recalculatePrice"
                                inline
                            >
                            </b-form-spinbutton>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            label="způsob dopravy"
                            :label-class="['text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="druh_dopravy"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="objednavka_data.druhy_dopravy"
                                    v-model="objednavka_data.druh_dopravy_id"
                                    @change="recalculatePrice"
                                >
                                </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            label="způsob platby"
                            :label-class="['text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="druh_platby"
                            label-align="left"
                            >
                                <b-form-select
                                    :options="objednavka_data.druhy_platby"
                                    v-model="objednavka_data.druh_platby_id"
                                    @change="recalculatePrice"
                                >
                                </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            label="cena zboží"
                            :label-class="['text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="cena_zbozi"
                            label-align="left"
                            >
                                <h4 class="font-weight-bold text-right text-white">
                                    {{ objednavka_data.cena_zbozi }} Kč
                                </h4>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            label="cena dopravy"
                            :label-class="['text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="cena_dopravy"
                            label-align="left"
                            >
                                <h4 class="font-weight-bold text-right text-white">
                                    {{ objednavka_data.cena_dopravy }} Kč
                                </h4>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <b-form-group
                            label="celkem k úhradě"
                            :label-class="['font-weight-bold','text-right','text-uppercase','text-white']"
                            :label-cols=true
                            label-for="cena_celkem"
                            label-align="left"
                        >
                            <h4 class="text-right">
                                <span class="badge badge-pill badge-white fs-4 pl-5 pr-5">
                                    {{ objednavka_data.cena_celkem }}
                                </span>
                                <span class="font-weight-bold text-white">
                                    Kč
                                </span>
                            </h4>
                            
                        </b-form-group>
                    </div>

                </form>-->
            </div>
        </div>

        <div class="card text-dark bg-grey">
            <div class="card-body">
                <div class="col-md-12">
                    <h2 class="font-weight-bold">
                        Osobní údaje
                    </h2>
                </div>

                <form class="row g-3">
                    <div class="col-md-6">
                        <b-form-group
                            label="Jméno"
                            label-for="osobni_udaje_jmeno"
                            label-align="left"
                            >
                                <b-form-input
                                    id="osobni_udaje_jmeno"
                                    v-model="objednavka_data.osobni_udaje.jmeno">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.jmeno')">
                                    {{ objednavka_data_errors["osobni_udaje.jmeno"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Příjmení"
                            label-for="osobni_udaje_prijmeni"
                            label-align="left"
                            >
                                <b-form-input
                                    id="osobni_udaje_prijmeni"
                                    v-model="objednavka_data.osobni_udaje.prijmeni">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.prijmeni')">
                                    {{ objednavka_data_errors["osobni_udaje.prijmeni"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Email"
                            label-for="osobni_udaje_email"
                            label-align="left"
                            >
                                <b-form-input
                                    id="osobni_udaje_email"
                                    v-model="objednavka_data.osobni_udaje.email">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.email')">
                                    {{ objednavka_data_errors["osobni_udaje.email"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Potvrzení emailu"
                            label-for="osobni_udaje_email_confirm"
                            label-align="left"
                            >
                                <b-form-input
                                    id="osobni_udaje_email_confirm"
                                    v-model="objednavka_data.osobni_udaje.email_confirm">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.email_confirm')">
                                    {{ objednavka_data_errors["osobni_udaje.email_confirm"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <!--<div class="col-md-12">
                        <b-form-group
                            label="Telefon"
                            label-for="osobni_udaje_telefon"
                            label-align="center"
                            label-cols-sm="4"
                            label-cols-lg="4"
                            content-cols-sm
                            content-cols-lg="8"
                            >
                                <b-form-input
                                    id="osobni_udaje_telefon"
                                    v-model="objednavka_data.osobni_udaje.telefon"
                                    class="w-50">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.telefon')"
                                    class="w-50">
                                    {{ objednavka_data_errors["osobni_udaje.telefon"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>-->

                    <div class="col-md-6">
                        <b-form-group
                            label="Telefon"
                            label-for="osobni_udaje_telefon"
                            label-align="left"
                            >
                                <b-form-input
                                    id="osobni_udaje_telefon"
                                    v-model="objednavka_data.osobni_udaje.telefon"
                                    class="w-50">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('osobni_udaje.telefon')">
                                    {{ objednavka_data_errors["osobni_udaje.telefon"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-12">
                        <h2 class="font-weight-bold">
                            Fakturační adresa
                        </h2>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Ulice"
                            label-for="fakturacni_adresa_ulice"
                            label-align="left"
                            >
                                <b-form-input
                                    id="fakturacni_adresa_ulice"
                                    v-model="objednavka_data.fakturacni_adresa.ulice">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('fakturacni_adresa.ulice')">
                                    {{ objednavka_data_errors["fakturacni_adresa.ulice"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Číslo popisné"
                            label-for="fakturacni_adresa_cislo_popisne"
                            label-align="left"
                            >
                                <b-form-input
                                    id="fakturacni_adresa_cislo_popisne"
                                    v-model="objednavka_data.fakturacni_adresa.cislo_popisne"
                                    class="w-50">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('fakturacni_adresa.cislo_popisne')"
                                    class="w-50">
                                    {{ objednavka_data_errors["fakturacni_adresa.cislo_popisne"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Město"
                            label-for="fakturacni_adresa_mesto"
                            label-align="left"
                            >
                                <b-form-input
                                    id="fakturacni_adresa_mesto"
                                    v-model="objednavka_data.fakturacni_adresa.mesto">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('fakturacni_adresa.mesto')">
                                    {{ objednavka_data_errors["fakturacni_adresa.mesto"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="PSČ"
                            label-for="fakturacni_adresa_psc"
                            label-align="left"
                            >
                                <b-form-input
                                    id="fakturacni_adresa_psc"
                                    v-model="objednavka_data.fakturacni_adresa.psc"
                                    class="w-25">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('fakturacni_adresa.psc')"
                                    class="w-50">
                                    {{ objednavka_data_errors["fakturacni_adresa.psc"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Země"
                            label-for="fakturacni_adresa_zeme"
                            label-align="left"
                            >
                                <b-form-input
                                    id="fakturacni_adresa_zeme"
                                    readonly
                                    v-model="objednavka_data.zeme_nazev">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('fakturacni_adresa.zeme')">
                                    {{ objednavka_data_errors["fakturacni_adresa.zeme"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-12 text-left">
                        <b-form-checkbox
                            id="dorucit_na_jinou_adresu"
                            v-model="objednavka_data.dorucit_na_jinou_adresu"
                            name="dorucit_na_jinou_adresu"
                            size="lg"
                            :value=true
                            :unchecked-value=false
                        >
                            Doručit na jinou adresu
                        </b-form-checkbox>
                    </div>

                    <div class="col-md-12 text-left">
                        <b-form-checkbox
                            id="nakup_na_firmu"
                            v-model="objednavka_data.nakup_na_firmu"
                            name="nakup_na_firmu"
                            size="lg"
                            :value=true
                            :unchecked-value=false
                        >
                            Nakupuji na firmu
                        </b-form-checkbox>
                    </div>

                </form>
            </div>
        </div>

        <div
            class="card text-dark bg-light-grey"
            v-show="objednavka_data.dorucit_na_jinou_adresu"
        >
            <div class="card-body">
                <div class="col-md-12">
                    <h2 class="font-weight-bold">
                        Dodací adresa
                    </h2>
                </div>

                <form class="row g-3">
                    <div class="col-md-6">
                        <b-form-group
                            label="Jméno"
                            label-for="dodaci_adresa_jmeno"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_jmeno"
                                    v-model="objednavka_data.dodaci_adresa.jmeno">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.jmeno')">
                                    {{ objednavka_data_errors["dodaci_adresa.jmeno"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Příjmení"
                            label-for="dodaci_adresa_prijmeni"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_prijmeni"
                                    v-model="objednavka_data.dodaci_adresa.prijmeni">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.prijmeni')">
                                    {{ objednavka_data_errors["dodaci_adresa.prijmeni"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Ulice"
                            label-for="dodaci_adresa_ulice"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_ulice"
                                    v-model="objednavka_data.dodaci_adresa.ulice">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.ulice')">
                                    {{ objednavka_data_errors["dodaci_adresa.ulice"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Číslo popisné"
                            label-for="dodaci_adresa_cislo_popisne"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_cislo_popisne"
                                    v-model="objednavka_data.dodaci_adresa.cislo_popisne"
                                    class="w-50">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.cislo_popisne')"
                                    class="w-50">
                                    {{ objednavka_data_errors["dodaci_adresa.cislo_popisne"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Město"
                            label-for="dodaci_adresa_mesto"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_mesto"
                                    v-model="objednavka_data.dodaci_adresa.mesto">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.mesto')">
                                    {{ objednavka_data_errors["dodaci_adresa.mesto"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="PSČ"
                            label-for="dodaci_adresa_psc"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_psc"
                                    v-model="objednavka_data.dodaci_adresa.psc"
                                    class="w-25">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.psc')"
                                    class="w-50">
                                    {{ objednavka_data_errors["dodaci_adresa.psc"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="Země"
                            label-for="dodaci_adresa_zeme"
                            label-align="left"
                            >
                                <b-form-input
                                    id="dodaci_adresa_zeme"
                                    readonly
                                    v-model="objednavka_data.zeme_nazev">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('dodaci_adresa.zeme')">
                                    {{ objednavka_data_errors["dodaci_adresa.zeme"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                </form>
            </div>
        </div>

        <div
            class="card text-dark bg-dark-grey"
            v-show="objednavka_data.nakup_na_firmu"
        >
            <div class="card-body">
                <div class="col-md-12">
                    <h2 class="font-weight-bold">
                        Společnost
                    </h2>
                </div>

                <form class="row g-3">
                    <div class="col-md-12">
                        <b-form-group
                            label="Název společnosti"
                            label-for="spolecnost_nazev"
                            label-align="left"
                            >
                                <b-form-input
                                    id="spolecnost_nazev"
                                    v-model="objednavka_data.spolecnost.nazev">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('spolecnost.nazev')">
                                    {{ objednavka_data_errors["spolecnost.nazev"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="IČ"
                            label-for="spolecnost_ic"
                            label-align="left"
                            >
                                <b-form-input
                                    id="spolecnost_ic"
                                    v-model="objednavka_data.spolecnost.ic">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('spolecnost.ic')">
                                    {{ objednavka_data_errors["spolecnost.ic"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group
                            label="DIČ"
                            label-for="spolecnost_dic"
                            label-align="left"
                            >
                                <b-form-input
                                    id="spolecnost_dic"
                                    v-model="objednavka_data.spolecnost.dic">
                                </b-form-input>
                                <b-form-invalid-feedback
                                    :force-show="fieldIsInvalid('spolecnost.dic')">
                                    {{ objednavka_data_errors["spolecnost.dic"] }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                </form>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-md-1 text-right mt-3">
                <b-form-checkbox
                    id="souhlasim_s_obchodnimi_podminkami"
                    size="lg"
                    v-model="objednavka_data.souhlasim_s_obchodnimi_podminkami"
                    name="souhlasim_s_obchodnimi_podminkami"
                    :value=true
                    :unchecked-value=false
                >
                </b-form-checkbox>
            </div>
            <div class="col-md-6 text-left mt-3">
                Souhlasím s
                <a
                    href="https://eshop.rancherskespeciality.cz/fotky101916/gdpr/docs/2022_08_26_165806_12_obchodni-podminky_masna.pdf"
                    target="_blank"
                >
                    obchodními podmínkami
                </a>
            </div>
        </div>-->

        <div class="col-md-12 text-left mt-3">
            <b-form-checkbox
                id="souhlasim_s_obchodnimi_podminkami"
                size="lg"
                v-model="objednavka_data.souhlasim_s_obchodnimi_podminkami"
                name="souhlasim_s_obchodnimi_podminkami"
                :value=true
                :unchecked-value=false
            >
                Souhlasím s obchodními podmínkami
            </b-form-checkbox>
            <a
                    href="https://eshop.rancherskespeciality.cz/fotky101916/gdpr/docs/2022_08_26_165806_12_obchodni-podminky_masna.pdf"
                    target="_blank"
                >
                    Obchodní podmínky
                </a>
        </div>

        <div class="col-md-12 mt-3 mb-3">
            <b-button
                class="fs-3 font-weight-bold pl-5 pr-5"
                :disabled="!objednavka_data.souhlasim_s_obchodnimi_podminkami"
                :pill=true
                variant="danger"
                @click="createOrder"
            >
                ODESLAT OBJEDNÁVKU
            </b-button>
        </div>

    </div>
</template>

<script>
import { /*createApplicationError,*/ createFatalError, createNotice , createWarning } from '@/lib/gms/error.js'
import { deepCopy } from '@/lib/gms/utils.js'
import { state, mutations } from '@/store/index.js'
import * as constants from '@/store/constants.js'
//import { defaultsProdukt } from '@/store/produkt.js'

export default {
    components: { },

    computed: {
        data_state_config( ) {
            return state.data_state.config.state
        },

        objednavka_data( ) {
            return state.objednavka_data
        },

        objednavka_data_errors( ) {
            return state.objednavka_data_errors
        }
    },

    data( ) {
        return { }
    },

    methods: {
        fieldIsInvalid(field) {
            //console.log(state.host_data_errors[field])
            return state.objednavka_data_errors[field] != null
        },

        recalculatePrice( ) {
            mutations.recalculatePrice( )
        },

        createOrder( ) {
            let objednavka = deepCopy(this.objednavka_data)

            mutations.createOrder(objednavka)
                .then(response => {
                    //console.log(response)

                    if (response.druh_platby_id == constants.DRUH_PLATBY_PLATEBNI_KARTA) {
                        // Platební karta
                        createNotice("Vyčkejte, budete přesměrováni na platební bránu.")

                        setTimeout(function( ) {
                            location.replace(response.redirect)
                        }, 1000)
                    } else {
                        // Převod
                        this.$router.push({
                            name: 'payment-transfer',
                            query: {
                                path: response.path,
                                refId: response.refId
                            }
                        })
                    }
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)

                    /*
                    // Chybové hlášení
                    if (this.objednavka_data_errors.message != null) {
                        this.$bvToast.toast(this.objednavka_data_errors.message, {
                            autoHideDelay: 3000,
                            appendToast: false,
                            solid: true,
                            title: this.$t('Error'),
                            toaster: 'b-toaster-top-center',
                            variant: "danger"
                        })
                    }
                    */
                })
        },

        readOrder(params) {
            //console.log(params)

            mutations.readOrder({ url_suffix: params.path })
                .then((/*response*/) => {
                    //console.log(response)

                    this.objednavka_data.url_suffix = params.path

                    mutations.recalculatePrice( )
                })
                .catch(errors => {
                    console.log(errors)
                })
        }
    },

    mounted( ) {
        let params = this.$route.query
        //console.log("OrderFormView.params", params)

        if (params.path == undefined) {
            createWarning('Nenalezen parametr "path".')
            createFatalError('Nenalezen parametr "path".')

            return
        }

        this.readOrder(params)
    },

    /*watch: {
        data_state_config(newConfigState, oldConfigState) {
            if (newConfigState == constants.DATA_STATE_READY) {
                mutations.recalculatePrice( )    
            }
        }
    }*/
}
</script>